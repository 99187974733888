/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "여섯 번째 에피소드에서는 KT 서울 서북부 통신 장애, AWS re:Invent 2018 이야기를 했습니다."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://news.chosun.com/site/data/html_dir/2015/09/25/2015092500305.html"
  }, "조선닷컴 - 회원이 올린 요리법 210만건… 재가공해 1조원 기업으로(쿡패드 소개 기사, 2015)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://news.hankyung.com/article/2018112507221"
  }, "한경닷컴 - 통신관 79m 화재에 마비된 서울 도심…백업 없는 '초연결사회'")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.yna.co.kr/view/AKR20181125019551017"
  }, "연합뉴스 - 황창규 KT 회장 \"적극적 보상안 마련…전국 시설 점검할 것\"(종합)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://news.donga.com/3/all/20181126/93025755/1"
  }, "식당-편의점 카드결제 마비… 자영업자들 “주말 장사 망쳐” 분통")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://news.hankyung.com/article/201811259842i"
  }, "한경닷컴 - KT 화재로 경찰 내부통신도 ‘먹통’, \"무전으로만 112지령 전달\" 치안 공백 우려")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://biz.chosun.com/site/data/html_dir/2018/04/09/2018040900028.html"
  }, "Chosunbiz - '2시간 반 장애' SKT, 730만명에 이틀치 요금 보상")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://biz.chosun.com/site/data/html_dir/2018/11/25/2018112501644.html"
  }, "\"1개월 요금 감면\"…KT 화재, 첫번째 보상안 나왔다 - Chosunbiz > 테크 > ICT/미디어")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://news.kbs.co.kr/news/view.do?ncd=4082268&ref=A"
  }, "KT 화재로 119 신고도 ‘먹통’…70대 여성 사망 > 뉴스광장 1부 > 사회 > 뉴스 - KBSNEWS")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.ddaily.co.kr/news/article.html?no=175389"
  }, "10년 제자리 중요통신시설 관리기준 “등급 재조정 시급”")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://reinvent.awsevents.com/learn/keynotes/"
  }, "re:Invent 2018 Keynotes"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "일: 미드나잇 매드네스(Midnight Madness)"), "\n", React.createElement(_components.li, null, "월: 먼데이 나이트 라이브(Monday Night Live)"), "\n", React.createElement(_components.li, null, "화: 파트너 키노트"), "\n", React.createElement(_components.li, null, "수: AWS CEO 키노트"), "\n", React.createElement(_components.li, null, "목: Amazon CTO 키노트"), "\n"), "\n"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=mDNHK-SzXEM"
  }, "YouTube - AWS re:Invent 2018 - Monday Night Live with Peter DeSantis")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/deepracer/"
  }, "AWS DeepRacer")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.amazon.com/dp/B07JMHRKQG"
  }, "Amazon.com: AWS DeepRacer")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.yes24.com/24/goods/44136413?scode=032&OzSrank=1"
  }, "YES24 - 파이썬과 케라스로 배우는 강화학습")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://pages.awscloud.com/reinvent-recap-2018-kr.html"
  }, "AWS re:Invent 특집 온라인 세미나"), " - 12월 12일"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/blogs/korea/new-for-amazon-dynamodb-global-tables-and-on-demand-backup/"
  }, "Amazon DynamoDB 업데이트 – 글로벌 테이블 및 온 디맨드 백업 기능 출시")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/personalize/"
  }, "AWS Amazon Personalize")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/marketplace/solutions/machinelearning/"
  }, "AWS Marketplace: Machine Learning & Artificial Intelligence")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/textract/"
  }, "AWS Amazon Textract")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://techlife.cookpad.com/entry/2016/03/16/100043"
  }, "쿡패드의 최근 마이크로서비스 사례(일본어)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/about-aws/whats-new/2018/09/amazon-route-53-auto-naming-available-in-five-additional-AWS-regions/"
  }, "Amazon Route 53 자동 이름 지정, 5개의 추가 리전에서 사용 가능")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/cloud-map/"
  }, "Cloud Map - Service discovery for cloud resources")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/app-mesh/"
  }, "AWS App Mesh - Monitor and Control Microservices - Amazon Web Services")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/outposts/"
  }, "AWS Outposts Overview page")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/certification/certification-prep/"
  }, "AWS 자격증 – 자격증 시험 준비")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/certification/benefits/"
  }, "AWS 자격증 – 자격증 혜택")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.google.com/maps/@33.8890866,-117.9221613,12z"
  }, "Google Map - Fullerton")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=IPi-ed1amOA"
  }, "일본 훗카이도 지진 정전사태에서 비상 전기로 60시간 운영한 데이터 센터 - YouTube")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://containerdays.jp/"
  }, "JapanContainerDays v18.12")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
